var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "lg",
      "opacity": "0.6",
      "spinner-variant": "primary"
    }
  }, [_c('div', {
    staticClass: "bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5"
  }, [_c('label', [_c('router-link', {
    staticClass: "text-capitalize text-gray-900 font-semibold",
    attrs: {
      "to": {
        name: _vm.NewspaperPath.name
      }
    }
  }, [_vm._v(" Newspapers")]), _vm._v(" "), _c('i', {
    staticClass: "fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"
  }), _vm._v(" "), _c('span', {
    staticClass: "text-gray-500 font-normal capitalize"
  }, [_vm._v(" Add New")])], 1), _c('h4', {
    staticClass: "text-xl capitalize"
  }, [_vm._v("New Newspaper")])]), _vm.show ? _c('form', {
    staticClass: "bg-white w-full rounded-lg mt-3 mb-3 px-4 py-4",
    attrs: {
      "id": "form-new"
    },
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "mt-3 flex justify-between"
  }, [_c('div', {
    staticClass: "w-full flex justify-between"
  }, [_c('div', {
    staticClass: "w-1/2 pr-10"
  }, [_c('div', {
    staticClass: "block"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600"
  }, [_vm._v("Brand")]), _c('multiselect', {
    staticClass: "w-full mr-4",
    attrs: {
      "label": "name",
      "track-by": "name",
      "placeholder": "Enter Brand",
      "open-direction": "bottom",
      "required": "required",
      "options": _vm.brandSearchOptions,
      "searchable": true,
      "loading": _vm.isBrandSearch,
      "close-on-select": true,
      "options-limit": 10,
      "multiple": false,
      "allow-empty": false
    },
    on: {
      "search-change": _vm.brandFind,
      "select": _vm.brandSelected
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var option = _ref.option;
        return [_c('span', {
          staticClass: "tooltip-container"
        }, [_vm._v(" " + _vm._s(option.name) + " "), option.name.length > 50 ? _c('span', {
          staticClass: "tooltip"
        }, [_vm._v(_vm._s(option.name))]) : _vm._e()])];
      }
    }], null, false, 3814125221),
    model: {
      value: _vm.form.brand,
      callback: function ($$v) {
        _vm.$set(_vm.form, "brand", $$v);
      },
      expression: "form.brand"
    }
  })], 1), _c('div', {
    staticClass: "block mt-3"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600"
  }, [_vm._v("Display Name (Item’s Title)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.display_name,
      expression: "form.display_name"
    }],
    staticClass: "w-full bg-gray-50 rounded-lg border",
    attrs: {
      "id": "display",
      "type": "text",
      "placeholder": "Enter Display Name",
      "required": ""
    },
    domProps: {
      "value": _vm.form.display_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "display_name", $event.target.value);
      }
    }
  })]), _c('div')])])]), _c('h4', {
    staticClass: "text-xl font-bold capitalize block border-t w-full mt-4 pt-3"
  }, [_vm._v("Detail")]), _c('div', {
    staticClass: "justify-start flex"
  }, [_c('div', {
    staticClass: "w-1/2 pr-10"
  }, [_c('div', {
    staticClass: "grid gap-4 grid-cols-2 mt-3 mb-3"
  }, [_c('div', {
    staticClass: "mt-0"
  }, [_c('label', {
    staticClass: "font-semibold text-gray-600 mb-3"
  }, [_vm._v("Printed Price Currency (Optional)")]), _c('t-select', {
    attrs: {
      "id": "currency",
      "options": _vm.currencys,
      "required": ""
    },
    model: {
      value: _vm.form.currency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "currency", $$v);
      },
      expression: "form.currency"
    }
  })], 1), _c('div', {
    staticClass: "mt-0"
  }, [_c('label', {
    staticClass: "font-semibold mb-3 text-gray-600"
  }, [_vm._v("Printed Price (Optional)")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.price,
      expression: "form.price"
    }],
    staticClass: "w-full bg-gray-50 rounded-lg border",
    attrs: {
      "id": "price",
      "type": "number",
      "step": "any",
      "placeholder": "99000 (no sparator)",
      "required": ""
    },
    domProps: {
      "value": _vm.form.price
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "price", $event.target.value);
      }
    }
  })])]), _c('DatetimeCustom', {
    attrs: {
      "label": 'Release Date (GMT ' + _vm.timezoneString() + ')'
    },
    model: {
      value: _vm.form.release_date,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_date", $$v);
      },
      expression: "form.release_date"
    }
  }), _c('DatetimeCustom', {
    staticClass: "mt-3",
    attrs: {
      "label": 'Release Schedule (GMT ' + _vm.timezoneString() + ')'
    },
    model: {
      value: _vm.form.release_schedule,
      callback: function ($$v) {
        _vm.$set(_vm.form, "release_schedule", $$v);
      },
      expression: "form.release_schedule"
    }
  }), _c('div', {
    staticClass: "flex justify-start mt-4"
  }, [_c('button', {
    staticClass: "bg-blue-700 p-2 px-2 text-white rounded-lg",
    attrs: {
      "type": "submit",
      "form": "form-new",
      "hidden": _vm.isLoading
    }
  }, [_vm._v(" Save ")]), _c('button', {
    staticClass: "bg-white border-blue-700 text-blue-700 border-l border-t border-b border-r rounded-lg ml-3 px-3",
    attrs: {
      "type": "reset"
    }
  }, [_vm._v("Reset Form")]), _c('button', {
    staticClass: "bg-white p-2 px-3 text-gray-800 border rounded-lg ml-3"
  }, [_c('router-link', {
    staticClass: "text-capitalize text-gray-800 font-normal",
    attrs: {
      "to": {
        name: _vm.NewspaperPath.name
      }
    }
  }, [_vm._v(" Cancel")])], 1)])], 1)])]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }