<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <div class="bg-white w-full rounded-lg mt-4 mb-3 px-4 py-2.5">
      <label> <router-link :to="{ name: NewspaperPath.name }" class="text-capitalize  text-gray-900 font-semibold">
          Newspapers</router-link> <i class="fa-solid fa-chevron-right mx-2 text-gray-500 text-xs font-normal"></i> <span
          class="text-gray-500 font-normal capitalize"> Add New</span>
      </label>
      <h4 class="text-xl capitalize">New Newspaper</h4>
    </div>
    <form class="bg-white w-full rounded-lg mt-3 mb-3 px-4 py-4"  @submit="onSubmit"  v-if="show" id="form-new">
      <div class="mt-3 flex justify-between">
        <div class="w-full flex justify-between">
          <div class="w-1/2 pr-10">
            <div class="block">
              <label class="font-semibold text-gray-600">Brand</label>
              <multiselect v-model="form.brand" label="name" track-by="name" placeholder="Enter Brand" class="w-full mr-4"
                open-direction="bottom" required="required" :options="brandSearchOptions" :searchable="true"
                :loading="isBrandSearch" :close-on-select="true" :options-limit="10" :multiple="false"
                :allow-empty="false" @search-change="brandFind" @select="brandSelected">
                <template #option="{ option }">
              <span class="tooltip-container">
                {{ option.name }}
                <span v-if="option.name.length > 50" class="tooltip">{{ option.name }}</span>
              </span>
            </template>
              </multiselect>
              
            </div>
            <div class="block mt-3">
              <label class="font-semibold text-gray-600">Display Name (Item’s Title)</label>
              <input id="display" v-model="form.display_name" class="w-full bg-gray-50 rounded-lg border " type="text"
                placeholder="Enter Display Name" required />
            </div>
            <div>
            </div>
          </div>
        </div>

      </div>
      <h4 class="text-xl font-bold capitalize block border-t w-full mt-4 pt-3">Detail</h4>
      <div class="justify-start flex">
        <div class="w-1/2 pr-10">
          <div class="grid gap-4 grid-cols-2 mt-3 mb-3">
            <div class="mt-0">
                <label class="font-semibold text-gray-600 mb-3">Printed Price Currency (Optional)</label>
                <t-select id="currency" v-model="form.currency" :options="currencys" required></t-select>
              </div>
              <div class="mt-0">
                <label class="font-semibold mb-3 text-gray-600">Printed Price (Optional)</label>
                <input id="price" v-model="form.price" type="number" class="w-full bg-gray-50 rounded-lg border"
                  step="any" placeholder="99000 (no sparator)" required />
              </div>
          </div>
          <DatetimeCustom  :label="'Release Date (GMT ' + timezoneString() + ')'" v-model="form.release_date">
          </DatetimeCustom>
          <DatetimeCustom class="mt-3" :label="'Release Schedule (GMT ' + timezoneString() + ')'"
            v-model="form.release_schedule"></DatetimeCustom>
       
          <div class="flex justify-start mt-4">
            <button class="bg-blue-700 p-2 px-2 text-white rounded-lg " type="submit" form="form-new"
              :hidden="isLoading">
              Save
            </button>
            <button
              class="bg-white border-blue-700  text-blue-700 border-l border-t border-b border-r rounded-lg ml-3 px-3"
              type="reset"
              >Reset Form</button>
            <button class="bg-white p-2 px-3 text-gray-800 border rounded-lg ml-3 ">
              <router-link :to="{ name: NewspaperPath.name }" class="text-capitalize  text-gray-800 font-normal">
                Cancel</router-link>
            </button>
          </div>
        </div>
      </div>
    </form>
  </b-overlay>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';
import { NewspaperPath } from '../../router/content';
import constant from '../../store/constant';
import {EventBus} from '../../event-bus'
import DatetimeCustom from "../../components/form/Datetime.vue"
const PRINTED_PRICE = Object.values(constant.PRINTED_PRICE_ITEM);

export default {
  name: "newnewspaper",
  components: {
    DatetimeCustom
  },
  data() {
    return {
      NewspaperPath,
      form: {
        brand: null,
        price:"",
        currency: 'IDR',
        display_name: "",
        release_date: moment().toString(),
        release_schedule: moment().toString(),
      },
      isBrandSearch: false,
      currencys: [
        { text: "Select Currency", value: null }, ...PRINTED_PRICE],
      brandSearchOptions: [],
      show: true,
      debounce: null,
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.newspaper.isError,
      isLoading: (state) => state.newspaper.isLoading,
      errorMessage: (state) => state.newspaper.errorMessage,
      successMessage: (state) => state.newspaper.successMessage,
      item: (state) => state.newspaper.item,
    }),
  },
  watch: {
    errorMessage: function () {
      if (!this.errorMessage) return;
    // this.messageAlert('error', this.errorMessage, 5000);
      EventBus.$emit('show-toast', {
            message:this.errorMessage,
            type: 'error',
          });

    },
    successMessage: function () {
      if (!this.successMessage) return;
    EventBus.$emit('show-toast', {
                    message: this.successMessage,
                    type: 'success',
                });
      this.$router.push(NewspaperPath);
    },
    'form.release_schedule': {
    handler() {
      this.updateDisplayName();
    },
    deep: true,
  },
}, 
 created() {
    this.brandFind('');
  },
  methods: {
    ...mapActions("brands", ["searchBrands"]),
    ...mapActions("newspaper", ["postNewspaper"]),
    onSubmit(event) {
      event.preventDefault();
      const payload = {
        brand_id: this.form.brand?.id,
        release_date: this.form.release_date,
        release_schedule: this.form.release_schedule,
        printed_price: this.form.price,
        printed_currency_code: this.form.currency
      };
      this.postNewspaper(payload);
    },

    brandFind(query) {
      this.isBrandSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          type: constant.ITEM_TYPES.NEWSPAPER.toLowerCase(),
          limit: 10,
        };
        this.searchBrands(payload).then((response) => {
          this.brandSearchOptions = response.data.data.rows;
          this.isBrandSearch = false;
        }).catch(() => {
          this.isBrandSearch = false;
        });
      }, 600);
    },

    async brandSelected() {
      if (!this.form.release_date || !this.form.brand) return;
      const dateNow = moment(this.form.release_date).format('DD MMM YYYY');
      this.form.display_name = `${this.form.brand?.name} / ${dateNow.toUpperCase()}`;
    },
    updateDisplayName() {
  if (moment(this.form.release_schedule).isValid()) {
    const release_schedule = moment(this.form.release_schedule).format('DD MMM YYYY').toUpperCase();
    const brandName = this.form.brand?.name || 'Enter Display Name';
    this.form.display_name = `${brandName} / ${release_schedule}`;
  } else {
    this.form.display_name = this.form.brand?.name || 'Enter Display Name';
  }
},
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },

    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  }
};
</script>

<style lang="css">
.create-new {
  font-family: "Avenir Next", "effra", Helvetica, Arial, sans-serif;
}
.button-date-custom .button-date-now {
  display: none;
}
</style>
